const contractAddresses = require("./contractAddresses.json");
const automationA_abi = require("./AutomationA.json");
const carrotStickExerciser_abi = require("./CarrotStickExerciser.json");
const luCoin_abi = require("./LuCoin.json");
const VRFD20_abi = require("./VRFD20.json");

module.exports = {
  contractAddresses,
  automationA_abi,
  carrotStickExerciser_abi,
  luCoin_abi,
  VRFD20_abi,
};
