import React from "react";

const Spinner = ({ children }) => {
  return (
    <div className="mt-[-8rem] text-center flex flex-col justify-center items-center h-screen">
      <div className=" animate-spin rounded-full h-8 w-8 md:h-12 md:w-12 border-t-2 border-b-2 border-slate-500"></div>
      {children}
    </div>
  );
};

export default Spinner;
