import React from "react";
import { useNavigate } from "react-router-dom";
import backgroundImage from "../assets/peak.png";
import Background from "./Background";
import SlashTime from "./SlashTime";
import Button from "./Button";

const Homepage = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/reportrun");
  };

  return (
    <Background backgroundImage={backgroundImage}>
      <SlashTime />
      <div className="container ml-2 px-4 md:px-6 lg:px-8 md:ml-0 my-auto">
        <h2 className="text-2xl md:text-4xl text-gray-700 font-semibold">
          carrotstick
        </h2>
        <h3 className="text-lg md:text-xl mb-8 text-gray-500">
          you can win the game.
        </h3>

        <Button text={"start now"} handleClick={handleClick} />
      </div>
    </Background>
  );
};

export default Homepage;
