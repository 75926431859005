import React, { useContext, useState } from "react";
import { NavLink } from "react-router-dom";
import DataContext from "../Context/DataContext";
import { RxHamburgerMenu } from "react-icons/rx";

const NavBar = () => {
  const data = useContext(DataContext);
  const logoUrl = process.env.PUBLIC_URL + "/carrotstick.png";
  const [menuIsClicked, setMenuIsClicked] = useState(false);

  const handleClick = () => {
    menuIsClicked ? setMenuIsClicked(false) : setMenuIsClicked(true);
  };

  const handleClose = () => {
    setMenuIsClicked(false);
  };

  return (
    <div className="relative">
      {menuIsClicked && (
        <div className="w-screen h-screen text-gray-400 bg-white absolute z-50 cursor-pointer">
          {" "}
          <div className="m-16 text-center">
            <nav>
              <ul className="flex flex-col mt-[10rem] space-y-8">
                <li>
                  <NavLink
                    to="/homepage"
                    onClick={handleClose}
                    className={({ isActive }) =>
                      isActive
                        ? "text-gray-400 underline font-bold"
                        : "text-gray-400"
                    }
                  >
                    HOMEPAGE
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/reportrun"
                    onClick={handleClose}
                    className={({ isActive }) =>
                      isActive
                        ? "text-gray-400 underline underline-offset-8"
                        : "text-gray-400"
                    }
                  >
                    REPORT RUN
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/reportgym"
                    onClick={handleClose}
                    className={({ isActive }) =>
                      isActive
                        ? "text-gray-400 underline underline-offset-8"
                        : "text-gray-400"
                    }
                  >
                    REPORT GYM
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/rewards"
                    onClick={handleClose}
                    className={({ isActive }) =>
                      isActive
                        ? "text-gray-400 underline underline-offset-8"
                        : "text-gray-400"
                    }
                  >
                    REWARDS
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/history"
                    onClick={handleClose}
                    className={({ isActive }) =>
                      isActive
                        ? "text-gray-400 underline underline-offset-8"
                        : "text-gray-400"
                    }
                  >
                    HISTORY
                  </NavLink>
                </li>
                <li>
                  {data.account ? (
                    <button type="button" className="">
                      {data.account.slice(0, 6) +
                        "..." +
                        data.account.slice(38, 42)}
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="text-sm"
                      onClick={data.connectHandler}
                    >
                      CONNECT
                    </button>
                  )}
                </li>
              </ul>
            </nav>
          </div>
          <button
            onClick={handleClose}
            className="absolute text-gray-400 top-0 right-6"
          >
            X
          </button>
        </div>
      )}

      <div className="font-montserrat font-bold sticky top-0 z-30 bg-white">
        <header className="h-8 md:h-20">
          <nav className="h-full">
            <div className="mt-2 mr-2 md:hidden">
              <RxHamburgerMenu
                onClick={handleClick}
                className="cursor-pointer text-2xl text-slate-500 ml-[22rem]"
              />
            </div>

            <ul className="hidden md:flex h-full list-none p-0 m-0 items-center justify-between mr-8">
              <li className="mx-4 w-40">
                <NavLink to="/homepage" type="submit">
                  <img
                    className="h-40 w-40 mx-auto my-2"
                    src={logoUrl}
                    alt="logo"
                  />
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/reportrun"
                  className={({ isActive }) =>
                    isActive
                      ? "text-gray-900 underline underline-offset-8"
                      : "text-gray-500"
                  }
                >
                  REPORT run
                </NavLink>
              </li>

              <li>
                <NavLink
                  to="/reportgym"
                  className={({ isActive }) =>
                    isActive
                      ? "text-gray-900 underline underline-offset-8"
                      : "text-gray-500"
                  }
                >
                  REPORT gym
                </NavLink>
              </li>

              <li>
                <NavLink
                  to="/rewards"
                  className={({ isActive }) =>
                    isActive
                      ? "text-gray-900 underline underline-offset-8"
                      : "text-gray-500"
                  }
                >
                  REWARDS
                </NavLink>
              </li>

              <li>
                <NavLink
                  to="/history"
                  className={({ isActive }) =>
                    isActive
                      ? "text-gray-900 underline underline-offset-8"
                      : "text-gray-500"
                  }
                >
                  HISTORY
                </NavLink>
              </li>

              <li>
                {data.account ? (
                  <button type="button" className="text-sm">
                    {data.account.slice(0, 6) +
                      "..." +
                      data.account.slice(38, 42)}
                  </button>
                ) : (
                  <button
                    type="button"
                    className="text-sm"
                    onClick={data.connectHandler}
                  >
                    CONNECT
                  </button>
                )}
              </li>
            </ul>
          </nav>
        </header>
      </div>
    </div>
  );
};

export default NavBar;
