import React, { useContext, useEffect, useState } from "react";
import DataContext from "../Context/DataContext";

const Wheel = ({ isSpinning, rewardMode }) => {
  const data = useContext(DataContext);
  const [rotation, setRotation] = useState(0);
  const segmentAngle = 360 / 10;
  const colors = [
    "#CCCCFF", // Lighter Blue
    "#FFB266", // Light Orange
    "#99FF99", // Light Green
    "#CCFFCC", // Lighter Green
    "#9999FF", // Light Blue
    "#FFCCCC", // Lighter Red
    "#FF99FF", // Light Violet
    "#FF9999", // Light Red
    "#FFFF99", // Light Yellow
    "#99FFFF", // Light Cyan
  ];
  const prizes = [9, 8, 7, 6, 5, 4, 3, 2, 1, 0];

  useEffect(() => {
    let intervalId;
    if (isSpinning) {
      console.log(`isSpinning is: ${isSpinning}`);
      intervalId = setInterval(() => {
        setRotation((prevRotation) => {
          const newRotation = prevRotation + 40;
          console.log(`newRotation is: ${newRotation}`);
          console.log(`setstate rotation is ${rotation}`);
          return newRotation;
        });
      }, 100);
    }
    return () => clearInterval(intervalId);
  }, [isSpinning]);

  useEffect(() => {
    const finalRotation = calculateFinalRotation(rewardMode);
    setRotation(finalRotation);
  }, [rewardMode]);

  const calculateFinalRotation = (rewardMode) => {
    const numOfFullSpins = 20;
    const baseRotation = 360 * numOfFullSpins;
    const offsetForSegment = segmentAngle * rewardMode;
    const finalRotation = baseRotation + offsetForSegment + 20;
    return finalRotation;
  };

  return (
    <div className="relative flex justify-center items-center my-10">
      <div className="mt-[-1rem] absolute top-0 w-0 h-0 border-x-8 border-x-transparent border-t-8 border-t-black"></div>
      <div
        className="wheel"
        style={{
          transition: "transform 2s ease-out",
          transform: `rotate(${rotation}deg)`,
        }}
      >
        <svg width="200" height="200" viewBox="0 0 200 200">
          {[...Array(10)].map((_, index) => {
            // Adjustments for text positioning
            const midAngle =
              ((index * 360) / 10 + ((index + 1) * 360) / 10) / 2 - 90;
            const textX = 100 + 70 * Math.cos((midAngle * Math.PI) / 180); // Closer to the center
            const textY = 100 + 70 * Math.sin((midAngle * Math.PI) / 180);

            return (
              <React.Fragment key={index}>
                <path
                  d={`M100,100 L${
                    100 +
                    100 * Math.cos((2 * Math.PI * index) / 10 - Math.PI / 2)
                  },${
                    100 +
                    100 * Math.sin((2 * Math.PI * index) / 10 - Math.PI / 2)
                  } A100,100 0 0,1 ${
                    100 +
                    100 *
                      Math.cos((2 * Math.PI * (index + 1)) / 10 - Math.PI / 2)
                  },${
                    100 +
                    100 *
                      Math.sin((2 * Math.PI * (index + 1)) / 10 - Math.PI / 2)
                  } Z`}
                  fill={colors[index]}
                />
                <text
                  x={textX}
                  y={textY}
                  fill="black"
                  textAnchor="middle"
                  alignmentBaseline="middle"
                  transform={`rotate(${midAngle + 90}, ${textX}, ${textY})`} // Align text
                  style={{
                    fontSize: "20px",
                    fontWeight: "bold",
                    userSelect: "none",
                  }}
                >
                  {prizes[index]}
                </text>
              </React.Fragment>
            );
          })}
        </svg>
      </div>
    </div>
  );
};

export default Wheel;
