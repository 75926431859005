import React from "react";

const Button = ({ text, handleClick }) => {
  return (
    <div>
      <button
        className="bg-white rounded-xl py-1 px-3 text-xs text-gray-400 md:py-2 md:px-4 md:text-base shadow-lg hover:bg-gray-600 hover:text-white transition-colors duration-150 "
        onClick={handleClick}
      >
        {text}
      </button>
    </div>
  );
};

export default Button;
