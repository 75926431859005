import React, { useContext, useState } from "react";
import DataContext from "../Context/DataContext";
import backgroundImage from "../assets/10.png";
import { CgGym } from "react-icons/cg";
import moment from "moment-timezone";
import SlashTime from "./SlashTime";
import Background from "./Background";
import Spinner from "./Spinner";
import Button from "./Button";

const ReportGym = () => {
  const data = useContext(DataContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const format = "dddd, MMMM Do YYYY, h:mm:ss a";

  const handleClick = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const signer = await data.provider.getSigner();
    let transaction = await data.carrotStickExerciser
      .connect(signer)
      .reportGuidedExercise()
      .catch((error) => console.error(error));
    const receipt = await transaction?.wait();
    console.log(receipt);
    setIsLoading(false);
    setIsSubmitted(true);
  };

  return (
    <div>
      <Background backgroundImage={backgroundImage}>
        <SlashTime className="text-white" />
        {isLoading && (
          <Spinner>
            <p className="mt-[-7rem] text-white">
              Submitting claims right now.
              <br />
              Please wait as this submission process may take up to 2 minutes.
            </p>
          </Spinner>
        )}{" "}
        {isSubmitted && (
          <div className="mt-[-8rem] text-center flex flex-col justify-center items-center text-white border-4 border-gray-100 border-y-gray-300 p-10">
            <p className="">
              Session has been submitted successfully. <br />
              No VRF has been executed as the reward for each session of gym /
              pilates fixed at $1000. <br />
              You have up till{" "}
              <span className="font-bold">
                {moment(data?.nextSlashTimeStamp * 1000)
                  .tz("Asia/Singapore")
                  .format(format)}
              </span>{" "}
              for your next run or gym/pilates.
              <br />
              Refer to REWARDS page for more information.
            </p>
          </div>
        )}
        {!isLoading && !isSubmitted && (
          <div className="flex flex-col items-center mt-[-1rem]">
            <div className="mb-2 flex items-center text-2xl md:text-4xl mt-[0.8rem] text-gray-100 ">
              {" "}
              <span>gym</span>
              <div className="mx-2">
                <CgGym className="text-gray-200" />
              </div>
              <span>pilates</span>
            </div>

            <h3 className="text-lg mb-8 text-gray-100 ">
              one session equals one hour
            </h3>

            <Button text={"submit today's session"} handleClick={handleClick} />
          </div>
        )}
      </Background>
    </div>
  );
};

export default ReportGym;
