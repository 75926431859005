import React, { useContext } from "react";
import DataContext from "../Context/DataContext";
import Button from "./Button";

const SummaryReport = ({
  date,
  accumulatedRewardBefore,
  rewardMode,
  rewardEarnedToday,
  accumulatedRewardAfter,
  typeOfExercise,
  hasClaimedReward,
  handleSubmit,
}) => {
  const data = useContext(DataContext);
  return (
    <>
      <div className="mt-4 md:text-base ml-4 text-xs">
        <table className="min-w-full divide-y divide-gray-200">
          <th className="px-6 py-3 text-left text-xs md:text-xl font-medium text-gray-500 uppercase tracking-wider">
            Summary Report
          </th>
          <tbody className="bg-white divide-y divide-gray-200">
            <tr className="bg-gray-100">
              <td>Date of Last Claim Submission</td>
              <td>{date}</td>
            </tr>
            <tr>
              <td>Type of Exercise Today</td>
              <td>{typeOfExercise}</td>
            </tr>
            <tr className="bg-gray-100 ">
              <td>Meters Ran Today</td>
              <td>
                {" "}
                {typeOfExercise === "gym / pilates"
                  ? "not applicable"
                  : `${data?.runMetersForToday} meters`}{" "}
              </td>
            </tr>
            <tr>
              <td>Seconds Ran Today</td>
              <td>
                {" "}
                {typeOfExercise === "gym / pilates"
                  ? "not applicable"
                  : `${data?.runSecondsForToday} seconds`}{" "}
              </td>
            </tr>
            <tr className="bg-gray-100 ">
              <td>Total Rewards before Today</td>
              <td>
                {" "}
                {hasClaimedReward ? "USD 0" : `USD ${accumulatedRewardBefore}`}
              </td>
            </tr>
            <tr>
              <td>Reward Mode Awarded Today</td>
              <td>
                {" "}
                {typeOfExercise === "gym / pilates"
                  ? "not applicable"
                  : rewardMode}{" "}
              </td>
            </tr>
            <tr className="bg-gray-100 ">
              <td>Rewards Earned Today</td>
              <td>
                {" "}
                {typeOfExercise === "gym / pilates"
                  ? "USD 100"
                  : `USD ${rewardEarnedToday}`}{" "}
              </td>
            </tr>
            <tr className="font-bold underline underline-offset-4">
              <td>TOTAL</td>
              <td>USD {accumulatedRewardAfter} </td>
            </tr>{" "}
          </tbody>
        </table>{" "}
        <div className="mt-4 ml-32 md:ml-[39rem]">
          <Button text={"claim rewards"} handleClick={handleSubmit} />
        </div>
      </div>
    </>
  );
};

export default SummaryReport;
