import React from "react";
import { IoHeartOutline } from "react-icons/io5";

const Footer = () => {
  return (
    <div>
      <div className="flex justify-center items-center text-sm mt-[0.8rem] text-gray-500">
        {" "}
        <span>made with</span>
        <div className="mx-2">
          <IoHeartOutline />
        </div>
        <span>by lucas</span>
      </div>
    </div>
  );
};

export default Footer;
