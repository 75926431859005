import React, { useEffect, useState } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { ethers } from "ethers";
import {
  contractAddresses,
  carrotStickExerciser_abi,
} from "./constants/index.js";
import DataContext from "./Context/DataContext";
import Homepage from "./components/Homepage.js";
import ReportRun from "./components/ReportRun.js";
import Rewards from "./components/Rewards.js";
import NavBar from "./components/NavBar.js";
import Footer from "./components/Footer.js";
import ReportGym from "./components/ReportGym.js";
import History from "./components/History.js";
import Wheel from "./components/Wheel.js";

function App() {
  const [provider, setProvider] = useState(null);
  const [carrotStickExerciser, setCarrotStickExerciser] = useState(null);
  const [account, setAccount] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [initialAccumulatedReward, setInitialAccumulatedReward] = useState(0);
  const [dailyRewardTarget, setDailyRewardTarget] = useState(0);
  const [dailyRunTargetMeters, setDailyRunTargetMeters] = useState(0);
  const [dailyMinRunMeters, setDailyMinRunMeters] = useState(0);
  const [dailyRunTargetSeconds, setDailyRunTargetSeconds] = useState(0);
  const [nextSlashTimeStamp, setNextSlashTimeStamp] = useState(0);
  const [runMetersForToday, setRunMetersForToday] = useState(0);
  const [runSecondsForToday, setRunSecondsForToday] = useState(0);
  const [rewardMode, setRewardMode] = useState(null);
  const [secondRandomness, setSecondRandomness] = useState(0);
  const [multiplier, setMultiplier] = useState(0);
  const [skipCard, setSkipCard] = useState(false);
  const [accumulatedReward, setAccumulatedReward] = useState(null);
  const [vrfCompleted, setVrfCompleted] = useState(false);
  const [rewardEarnedToday, setRewardEarnedToday] = useState(0);

  const loadBlockchainData = async () => {
    // Connect to blockchain
    const provider = new ethers.BrowserProvider(window.ethereum);
    setProvider(provider);
    const network = await provider.getNetwork();

    // Get JS version of contract
    const carrotStickExerciser = new ethers.Contract(
      contractAddresses[network.chainId].carrotStickExerciser.address,
      carrotStickExerciser_abi,
      provider
    );
    setCarrotStickExerciser(carrotStickExerciser);

    const accounts = await window.ethereum.request({
      method: "eth_requestAccounts",
    });
    setAccount(accounts[0]);

    // Fetch information/stats from blockchain
    if (initialAccumulatedReward == 0) {
      let results = await carrotStickExerciser.accumulatedReward();
      setInitialAccumulatedReward(parseInt(ethers.formatUnits(results, 6)));
    }

    let results = await carrotStickExerciser.dailyRewardTarget();
    setDailyRewardTarget(parseInt(ethers.formatUnits(results, 6)));

    results = await carrotStickExerciser.dailyRunTargetMeters();
    setDailyRunTargetMeters(results?.toString());

    results = await carrotStickExerciser.dailyMinRunMeters();
    setDailyMinRunMeters(results?.toString());

    results = await carrotStickExerciser.dailyRunTargetSeconds();
    setDailyRunTargetSeconds(results?.toString());

    results = await carrotStickExerciser.nextSlashTimeStamp();
    setNextSlashTimeStamp(results?.toString());

    results = await carrotStickExerciser.runMetersForToday();
    setRunMetersForToday(results?.toString());

    results = await carrotStickExerciser.runSecondsForToday();
    setRunSecondsForToday(results?.toString());

    results = await carrotStickExerciser.skipCard();
    setSkipCard(results);

    results = await carrotStickExerciser.accumulatedReward();
    setAccumulatedReward(
      parseInt(ethers.formatUnits(results, 6)).toLocaleString("en-US")
    );
    console.log(`Contract Address: ${carrotStickExerciser.target}`);
  };

  // Call loadBlockchainData function on mount
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        await loadBlockchainData();
      } catch (error) {
        console.error("An error has occured: ", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  // Set up event listener on mount
  // Call loadBlockchainData function on event fired
  useEffect(() => {
    if (!carrotStickExerciser) return;
    const handleRewardsGenerated = async (
      rewardMode,
      secondRandomness,
      multiplier,
      rewardEarnedToday
    ) => {
      await loadBlockchainData();
      console.log(
        `Reward Mode Generated: ${rewardMode}, Second Randomness: ${secondRandomness}, Multiplier: ${multiplier}, Reward Earned Today: ${rewardEarnedToday}`
      );
      setRewardMode(rewardMode.toString());
      setSecondRandomness(secondRandomness.toString());
      setMultiplier(multiplier.toString());
      setRewardEarnedToday(parseInt(ethers.formatUnits(rewardEarnedToday, 6)));
      console.log(rewardEarnedToday);
      setVrfCompleted(true);
    };
    console.log("Setting up event listener for rewardsGenerated...");
    carrotStickExerciser?.on("rewardsGenerated", handleRewardsGenerated);
    console.log("Event listener set.");
    console.log(accumulatedReward);
  }, [carrotStickExerciser]);

  useEffect(() => {
    const handleRewardsGenerated = async (
      rewardMode,
      secondRandomness,
      multiplier,
      rewardEarnedToday
    ) => {
      await loadBlockchainData();
      console.log(
        `Reward Mode Generated: ${rewardMode}, Second Randomness: ${secondRandomness}, Multiplier: ${multiplier}, Reward Earned Today: ${rewardEarnedToday}`
      );
      setRewardMode(rewardMode.toString());
      setSecondRandomness(secondRandomness.toString());
      setMultiplier(multiplier.toString());
      setRewardEarnedToday(parseInt(ethers.formatUnits(rewardEarnedToday, 6)));
    };
    console.log(rewardEarnedToday);
  }, [carrotStickExerciser]);

  // Update page if user changes metamask account
  window.ethereum.on("accountsChanged", async () => {
    // Refetch accounts
    const accounts = await window.ethereum.request({
      method: "eth_requestAccounts",
    });
    setAccount(accounts[0]);
  });

  // For unconnected metamask users
  const connectHandler = async () => {
    const accounts = await window.ethereum.request({
      method: "eth_requestAccounts",
    });
    setAccount(accounts[0]);
    console.log(account);
  };

  return (
    <DataContext.Provider
      value={{
        account,
        setAccount,
        carrotStickExerciser,
        provider,
        connectHandler,
        initialAccumulatedReward,

        nextSlashTimeStamp,
        setRunMetersForToday,
        runMetersForToday,
        setRunSecondsForToday,
        runSecondsForToday,

        dailyRewardTarget,
        setDailyRewardTarget,
        dailyRunTargetMeters,
        setDailyRunTargetMeters,
        dailyMinRunMeters,
        setDailyMinRunMeters,
        dailyRunTargetSeconds,
        setDailyRunTargetSeconds,

        rewardMode,
        setRewardMode,
        secondRandomness,
        setSecondRandomness,
        multiplier,
        setMultiplier,
        skipCard,
        setSkipCard,
        accumulatedReward,
        setAccumulatedReward,
        rewardEarnedToday,

        vrfCompleted,
        setVrfCompleted,
      }}
    >
      <div>
        <NavBar />
        <Routes>
          {" "}
          <Route path="/" element={<Navigate replace to="/homepage" />} />
          <Route path="/homepage" element={<Homepage />} />
          <Route path="/reportrun" element={<ReportRun />} />
          <Route path="/rewards" element={<Rewards />} />
          <Route path="/reportgym" element={<ReportGym />} />
          <Route path="/history" element={<History />} />
          <Route path="/wheel" element={<Wheel />} />
        </Routes>
        <Footer />
      </div>
    </DataContext.Provider>
  );
}

export default App;
