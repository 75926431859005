import React, { useContext } from "react";
import moment from "moment-timezone";
import DataContext from "../Context/DataContext";
const SlashTime = ({ className }) => {
  const data = useContext(DataContext);
  const format = "dddd, MMMM Do YYYY, h:mm:ss a";
  return (
    <div
      className={`absolute top-4 left-4 text-xs ${className} md:top-10 md:text-base md:left-10 `}
    >
      NEXT SLASH TIME: <br />
      <span className="font-bold">
        {moment(data?.nextSlashTimeStamp * 1000)
          .tz("Asia/Singapore")
          .format(format)}
      </span>
    </div>
  );
};

export default SlashTime;
