import React, { useState, useContext, useEffect } from "react";
import DataContext from "../Context/DataContext";
import { ethers } from "ethers";
import Confetti from "react-confetti";
import SummaryReport from "./SummaryReport";
import RewardsComputation from "./RewardsComputation";
import Spinner from "./Spinner";

const Rewards = () => {
  const data = useContext(DataContext);
  const [isLoading, setIsLoading] = useState(false);
  const [contractJustDeployed, setContractJustDeployed] = useState(false);
  const [firstExerciseJustCompleted, setFirstExerciseJustCompleted] =
    useState(false);
  const [rewardMode, setRewardMode] = useState(null);
  const [secondRandomness, setSecondRandomness] = useState(null);
  const [multiplier, setMultiplier] = useState(null);
  const [accumulatedRewardAfter, setAccumulatedRewardAfter] = useState(null);
  const [rewardEarnedToday, setRewardEarnedToday] = useState(null);
  const [typeOfExercise, setTypeOfExercise] = useState(null);
  const [date, setDate] = useState(null);
  const [accumulatedRewardBefore, setAccumulatedRewardBefore] = useState(null);
  const [hasClaimedReward, setHasClaimedReward] = useState(false);
  const [secondExerciseJustCompleted, setSecondExerciseJustCompleted] =
    useState(false);

  const [isClaiming, setIsClaiming] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [displayZeroMessage, setDisplayZeroMessage] = useState(false);

  // Fetch data on mount and when carrotStickExerciser, provider, accumulatedReward change
  useEffect(() => {
    console.log(`secondExerciseJustCompleted: ${secondExerciseJustCompleted}`);
    // Set up flag to track mounting status
    let isMounted = true;
    setIsLoading(true);

    // Fetch events
    // If events.length === 0, it means contract just deployed. Display "Unlock $1k" message
    // If events.length === 1, it means first run has just completed. Display "first run" message
    // If events.length >= 2, fetch latest event, save variables and display in table; because there is a table, it means claim rewards button will be made available
    // Do not render accumulated rewards before if there was a claim in previous event (save status in hasClaimedReward state)

    const getPastEvents = async (
      carrotStickExerciser,
      rewardsGenerated,
      fromBlock = 5349124,
      toBlock = "latest"
    ) => {
      const events = await (data?.carrotStickExerciser?.queryFilter(
        data?.carrotStickExerciser?.filters.rewardsGenerated(),
        fromBlock,
        toBlock
      ) || []); // empty array [] serves as a default value to ensure that events is always an array, for preventing errors in later parts of the code that might iterate over events

      if (events[events.length - 1]?.args[6]) {
        console.log("Check if claim");
        setIsLoading(false);
        setDisplayZeroMessage(true);
        return;
      } else {
        if (events?.length === 0) {
          console.log("reached events.length is 0");
          setIsLoading(false);
          setContractJustDeployed(true);
          return;
        }
        if (events.length === 1) {
          console.log("reached events.length is 1");
          console.log(events);
          const latestEvent = events[events.length - 1];
          setRewardEarnedToday(
            parseInt(ethers.formatUnits(latestEvent?.args[4], 6))
          );
          setIsLoading(false);
          setFirstExerciseJustCompleted(true);
          return;
        }
        if (events.length >= 2) {
          console.log("reached events.length is 2");
          console.log(events);
          const latestEvent = events[events.length - 1];
          setRewardMode(latestEvent?.args[0].toString());
          setSecondRandomness(latestEvent?.args[1].toString());
          setMultiplier(latestEvent?.args[2].toString() / 1000);
          setAccumulatedRewardAfter(
            parseInt(ethers.formatUnits(latestEvent?.args[3], 6))
          );
          setRewardEarnedToday(
            parseInt(ethers.formatUnits(latestEvent?.args[4], 6))
          );

          setTypeOfExercise(latestEvent?.args[5]);
          const getBlockTimeStamp = async (provider, blockNumber) => {
            const block = await data?.provider?.getBlock(blockNumber);
            const dateTime = new Date(block.timestamp * 1000);
            const formatedDateTime = dateTime.toLocaleString("en-US", {
              weekday: "long",
              year: "numeric",
              month: "long",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            });
            setDate(formatedDateTime);
          };
          await getBlockTimeStamp(data.provider, latestEvent?.blockNumber);
          const secondLastEvent = events[events.length - 2];
          setAccumulatedRewardBefore(
            parseInt(ethers.formatUnits(secondLastEvent?.args[3], 6))
          );
          setHasClaimedReward(secondLastEvent?.args[6]);
          setSecondExerciseJustCompleted(true);
          setIsLoading(false);
        }
      }
    };
    console.log(`contractJustDeployed: ${contractJustDeployed}`);
    console.log(`secondExerciseJustCompleted: ${secondExerciseJustCompleted}`);
    if (data?.carrotStickExerciser) {
      getPastEvents();
    }
  }, [data?.carrotStickExerciser, data.provider, data?.accumulatedReward]);

  // Claim rewards
  const handleSubmit = async () => {
    console.log("Hello");
    setIsClaiming(true);
    const signer = await data.provider.getSigner();
    let transaction = await data.carrotStickExerciser
      .connect(signer)
      .claim()
      .catch((error) => console.error(error));
    const receipt = await transaction?.wait();
    console.log(receipt);
    setIsClaiming(false);
    setShowConfetti(true);
  };

  //////////////////   RETURN VALUES AND DISPLAY ON WEBPAGE //////////////////
  return (
    <div>
      {isLoading && (
        <Spinner>
          <p className="mt-[-7rem] ">Loading...</p>
        </Spinner>
      )}
      {contractJustDeployed && (
        <div className="flex justify-center items-center h-[41rem] text-center">
          <p className="mt-[-10rem]">
            Contact has just been deployed with a USD {data.dailyRewardTarget}{" "}
            bonus locked in.
            <br />
            Unlock this bonus now on top of the daily reward with your first run
            / gym / pilates session.
          </p>
        </div>
      )}
      {firstExerciseJustCompleted && (
        <div className="flex justify-center items-center h-screen text-center">
          <p className="mt-[-10rem]">
            Good job in completing your first run / gym / pilates session!
            <br />
            Your rewards earned today is{" "}
            <span className="font-bold">USD {rewardEarnedToday}</span>, bringing
            your total earnings to{" "}
            <span className="font-bold">USD {data?.accumulatedReward}</span>!
            <br />
            <br />
            <p className="italic">
              "Push beyond your limits, for the pain you feel today is the
              strength you'll cherish tomorrow. <br />
              Keep moving, your greatest achievements are yet to be realized."
            </p>
          </p>
        </div>
      )}
      {secondExerciseJustCompleted && !isClaiming && !showConfetti && (
        <div>
          <SummaryReport
            date={date}
            accumulatedRewardBefore={accumulatedRewardBefore}
            rewardMode={rewardMode}
            rewardEarnedToday={rewardEarnedToday}
            accumulatedRewardAfter={accumulatedRewardAfter}
            typeOfExercise={typeOfExercise}
            hasClaimedReward={hasClaimedReward}
            handleSubmit={handleSubmit}
          />
          <RewardsComputation
            secondRandomness={secondRandomness}
            multiplier={multiplier}
            rewardMode={rewardMode}
            rewardEarnedToday={rewardEarnedToday}
            typeOfExercise={typeOfExercise}
            accumulatedRewardBefore={accumulatedRewardBefore}
          />
        </div>
      )}
      {isClaiming && (
        <Spinner>
          <p className="mt-[-7rem] ">
            Submitting claims right now.
            <br />
            Please wait as this submission process may take up to 2 minutes.
          </p>
        </Spinner>
      )}
      {showConfetti && (
        <div>
          <Confetti />
          <div className="flex justify-center items-center h-[41rem] text-center">
            <p className="mt-[-10rem]">
              Congratulations on coming so far. <br />
              Your claim of the rewards is successful. Check your wallet!
            </p>
          </div>
        </div>
      )}{" "}
      {displayZeroMessage && !showConfetti && (
        <div className="flex justify-center items-center h-[41rem] text-center">
          <p className="mt-[-10rem]">
            Your accumulated reward is $0.
            <br />
            <br /> Start a{" "}
            <span className="font-bold">run / gym / pilates</span> now to
            kickstart your rewards earning journey!
          </p>
        </div>
      )}
    </div>
  );
};

export default Rewards;
