import React from "react";
import { Scatter } from "react-chartjs-2";
import "chart.js/auto";
import "chartjs-adapter-date-fns";

const Chart = ({ runData, handleSubmit }) => {
  const formatDateString = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString("en-US", {
      weekday: "long", // "Tuesday"
      year: "numeric", // "2024"
      month: "long", // "February"
      day: "numeric", // "20"
      hour: "2-digit", // "18"
      minute: "2-digit", // "08"
      hour12: true, // not use 24-hour format
    });
  };

  const data = {
    datasets: [
      {
        label: "Runs",
        data: runData?.map((run) => ({
          x: new Date(run.date),
          y: run.meters,
          _id: run._id,
          seconds: run.seconds,
          txHash: run.txHash,
          status: run.status,
          formattedDate: formatDateString(run.date),
        })),
        backgroundColor: (context) => {
          const status = context.raw.status;
          return status === "claimed" ? "black" : "orange";
        },
        borderColor: "transparent",
        pointRadius: 4,
      },
    ],
  };

  const options = {
    responsive: true,
    scales: {
      x: {
        type: "time",
        time: {
          unit: "day",
        },
        title: {
          display: true,
          text: "Year 2024",
          color: "black",
          font: { size: 14, weight: "bold" },
          padding: 10,
        },
        ticks: { color: "black" },
      },
      y: {
        title: {
          display: true,
          text: "Meters Ran",
          color: "black",
          font: { size: 14, weight: "bold" },
          padding: 10,
        },
        ticks: { color: "black" },
      },
    },
    plugins: {
      legend: {
        labels: {
          color: "black",
          font: { weight: "bold" },
          padding: 10,
          usePointStyle: false,
        },
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const data = context.raw;
            const labels = [];
            labels.push(`Meters: ${data.y}`);
            labels.push(`Seconds: ${data.seconds}`);
            labels.push(`Date: ${data.formattedDate}`);
            labels.push(`ID: ${data._id}`);
            if (data.txHash) {
              labels.push(`Txhash: ${data.txHash}`);
            }
            return labels;
          },
        },
      },
    },
    onClick: (event, elements, chart) => {
      if (elements.length > 0) {
        const elementIndex = elements[0].index;
        const datasetIndex = elements[0].datasetIndex;
        const data = chart.data.datasets[datasetIndex].data[elementIndex];
        if (data.status === "new") {
          handleSubmit(data);
        }
      }
    },
  };

  return <Scatter data={data} options={options} />;
};

export default Chart;
