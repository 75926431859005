import React from "react";

const Background = ({ backgroundImage, children }) => {
  return (
    <div
      className="flex relative justify-center items-center py-[16rem]"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: "650px",
        width: "100%",
      }}
    >
      {children}
    </div>
  );
};

export default Background;
