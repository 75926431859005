import React, { useContext, useEffect, useState } from "react";
import DataContext from "../Context/DataContext";
import backgroundImage from "../assets/runpic.png";
import Background from "./Background";
import Chart from ".//Chart.js";
import moment from "moment-timezone";
import SlashTime from "./SlashTime";
import Spinner from "./Spinner";
import Wheel from "./Wheel";

const ReportRun = () => {
  const data = useContext(DataContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [runData, setRunData] = useState([]);
  const [isWaitingForVrf, setIsWaitingForVrf] = useState(false);
  const [noRunMessage, setNoRunMessage] = useState(false);
  const format = "dddd, MMMM Do YYYY, h:mm:ss a";
  const [displayWinningMessage, setDisplayWinningMessage] = useState(false);
  const [rewardMode, setRewardMode] = useState(null);
  const [isSpinning, setIsSpinning] = useState(false);

  let urlSepolia = "http://localhost:4000/";
  // Fetch data from runkeeper
  useEffect(() => {
    fetch("https://carrot.chain.ninja/")
      .then((response) => {
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.includes("application/json")) {
          return response.json();
        }
        throw new TypeError("Oops, we do not have JSON");
      })
      .then((data) => {
        setRunData(data.activities);
      })
      .catch((error) => {
        console.error("Fetch error:", error.message);
        setNoRunMessage(true);
      });
  }, []);

  // For testing the wheel, plus setIsWaitingForVrf(true) above:
  // useEffect(() => {
  //   setIsSpinning(true);
  //   setTimeout(() => {
  //     setRewardMode(2);
  //     setIsSpinning(false);
  //   }, 10000);
  // }, []);

  // Submit a run
  const handleSubmit = async (e) => {
    setIsSubmitting(true);
    const url = `https://carrot.chain.ninja/${e._id}`;
    const requestBody = {
      status: "claimed",
      contractAddress: data.carrotStickExerciser.target,
    };
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(requestBody),
      });
      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }
      const updatedRunData = runData.map((item) =>
        item._id === e._id ? { ...item, status: "claimed" } : item
      );
      setRunData(updatedRunData);
      console.log("Run claimed successfully");
      const responseData = await response.json();
      console.log("Success:", responseData);
      setIsSubmitting(false);
      setIsWaitingForVrf(true); // make wheel visible

      setIsSpinning(true); // make wheel spin
    } catch (error) {
      console.error("Error", error);
    }
  };

  // When VRF is completed
  useEffect(() => {
    if (data?.vrfCompleted && data?.rewardMode) {
      setRewardMode(data?.rewardMode);
      setIsSpinning(false); // stop spinning but don't hide the wheel yet

      setTimeout(() => {
        setIsWaitingForVrf(false); // now hide the wheel
        setDisplayWinningMessage(true); // now show the summarised result message
      }, 4000); // wheel will stay visible for 5 more seconds after completion of Vrf
    }
  }, [data?.vrfCompleted, data?.rewardMode]);

  return (
    <div>
      {isSubmitting && (
        <Spinner>
          <p className="mt-[-7rem] ">
            Submitting claims right now.
            <br />
            Please wait as this submission process may take up to 2 minutes.
          </p>
        </Spinner>
      )}{" "}
      {
        isWaitingForVrf && (
          <div className="mt-[10rem] md:mt-[12rem] text-center">
            {" "}
            <p className="mt-[-7rem]">
              Claims have been successfully submitted. <br />
              We are now executing the VRF to determine the reward mode. <br />
              Be patient, this may take up to 2 minutes.
              <br />
              <span className="font-bold">So... Is this your lucky day?</span>
            </p>
            <Wheel isSpinning={isSpinning} rewardMode={rewardMode} />
          </div>
        )
        /* <Spinner>
          <p className="mt-[-7rem] ">
            Claims have been submitted successfully. <br />
            Please wait (process may take up to 3 minutes) as we are now
            executing the VRF to determine the reward mode.
          </p>
        </Spinner> */
      }
      {displayWinningMessage && (
        <div className="mt-[-10rem] m-5 md:mt-[-18rem] text-center flex flex-col justify-center items-center h-screen">
          <p className="mt-[-7rem]">
            VRF has successfully generated a reward mode{" "}
            <span className="underline font-bold">{data?.rewardMode}</span>,
            <br />
            bringing your total Accumulated Reward to{" "}
            <span className="font-bold">
              USD {data?.accumulatedReward}
            </span>. <br />
            You have up till{" "}
            <span className="font-bold">
              {moment(data?.nextSlashTimeStamp * 1000)
                .tz("Asia/Singapore")
                .format(format)}
            </span>{" "}
            for your next run or gym/pilates.
            <br />
            Refer to REWARDS page for more information.
          </p>
        </div>
      )}
      {!isSubmitting &&
        !isWaitingForVrf &&
        !displayWinningMessage &&
        !data?.vrfCompleted &&
        runData.length > 0 && (
          <Background backgroundImage={backgroundImage}>
            <div className="ml-[-3rem] mt-[-14rem] md:h-[48rem] md:w-[48rem] md:ml-[-18rem] md:mt-[24rem]">
              <Chart runData={runData} handleSubmit={handleSubmit} />
            </div>
            <SlashTime />
          </Background>
        )}
      {noRunMessage && (
        <p className="mt-[-7rem] ">
          You are seeing a blank page because there had been no runs yet. <br />
          Register your first run today to claim your reward!
        </p>
      )}
    </div>
  );
};

export default ReportRun;
