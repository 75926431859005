import React, { useContext } from "react";
import DataContext from "../Context/DataContext";
const RewardsComputation = ({
  secondRandomness,
  multiplier,
  rewardMode,
  rewardEarnedToday,
  typeOfExercise,
  accumulatedRewardBefore,
}) => {
  const data = useContext(DataContext);
  return (
    <div className="mt-4 md:text-base ml-4 text-xs">
      <table className="min-w-full divide-y divide-gray-200">
        <th className="px-6 py-3 text-left text-xs md:text-xl font-medium text-gray-500 uppercase tracking-wider">
          Computation
        </th>
        <tbody className="bg-white divide-y divide-gray-200">
          <tr>
            <td>Daily Reward Target</td>
            <td>USD {data?.dailyRewardTarget}</td>
          </tr>

          <tr>
            <td>Daily Run Target in Meters</td>
            <td>{data?.dailyRunTargetMeters}</td>
          </tr>

          <tr>
            <td>Daily Run Target in Seconds</td>
            <td>{data?.dailyRunTargetSeconds}</td>
          </tr>

          <tr>
            <td>Second Randomness</td>
            <td>
              {typeOfExercise === "gym / pilates"
                ? "not applicable"
                : secondRandomness}
            </td>
          </tr>

          <tr>
            <td>Multiplier</td>
            <td>
              {typeOfExercise === "gym / pilates"
                ? "not applicable"
                : multiplier / 1000}
            </td>
          </tr>

          <tr
            style={{
              backgroundColor:
                rewardMode === "0" && typeOfExercise != "gym / pilates"
                  ? "#00cc88"
                  : "transparent",
              color:
                rewardMode === "0" && typeOfExercise != "gym / pilates"
                  ? "#ffffff"
                  : "inherit",
            }}
          >
            <td>0: Skipcard Status</td>
            <td>
              {data?.skipCard ? (
                <p>1 Skipcard Available For Use</p>
              ) : (
                <p>None</p>
              )}
            </td>
          </tr>

          <tr
            style={{
              backgroundColor:
                rewardMode === "1" || rewardMode === "2"
                  ? "#00cc88"
                  : "transparent",
              color:
                rewardMode === "1" || rewardMode === "2"
                  ? "#ffffff"
                  : "inherit",
            }}
          >
            <td>1 or 2: Direct Reward Proportional to Distance Run</td>
            <td>
              {" "}
              accumulatedReward += (dailyRewardTarget * runMetersForToday) /
              dailyRunTargetMeters
              <br />
              {`${accumulatedRewardBefore} + (${data.dailyRewardTarget} * ${data?.runMetersForToday} / ${data.dailyRunTargetMeters}) = `}
              {Math.round(
                accumulatedRewardBefore +
                  (data.dailyRewardTarget * data.runMetersForToday) /
                    data.dailyRunTargetMeters
              )}
            </td>
          </tr>

          <tr
            style={{
              backgroundColor: rewardMode === "3" ? "#00cc88" : "transparent",
              color: rewardMode === "3" ? "#ffffff" : "inherit",
            }}
          >
            <td>3: Direct Reward Proportional to Run Time</td>
            <td>
              {" "}
              accumulatedReward += (dailyRewardTarget * runSecondsForToday) /
              dailyRunTargetSeconds
              <br />
              {`${accumulatedRewardBefore} + (${data.dailyRewardTarget} * ${data.runSecondsForToday} / ${data.dailyRunTargetSeconds}) = `}
              {Math.round(
                accumulatedRewardBefore +
                  Math.floor(
                    (data.dailyRewardTarget * data.runSecondsForToday) /
                      data.dailyRunTargetSeconds
                  )
              )}
            </td>
          </tr>

          <tr
            style={{
              backgroundColor: rewardMode === "4" ? "#00cc88" : "transparent",
              color: rewardMode === "4" ? "#ffffff" : "inherit",
            }}
          >
            <td>4: Random Reward</td>
            <td>
              {" "}
              accumulatedReward += (dailyRewardTarget * (secondRandomness % 10))
              / 5<br />
              {`${Number(accumulatedRewardBefore)} + (${Number(
                data.dailyRewardTarget
              )} * ${Number(secondRandomness)} % 10) / 5 = `}
              {Math.round(
                Number(accumulatedRewardBefore) +
                  (Number(data.dailyRewardTarget) *
                    (Number(secondRandomness) % 10)) /
                    5
              )}
            </td>
          </tr>

          <tr
            style={{
              backgroundColor: rewardMode === "5" ? "#00cc88" : "transparent",
              color: rewardMode === "5" ? "#ffffff" : "inherit",
            }}
          >
            <td>
              5: Random Reward Bonus + Direct Reward Proportional to the Run
              Amount
            </td>
            <td>
              {" "}
              accumulatedReward += (dailyRewardTarget * (secondRandomness % 6))
              / 10 + (dailyRewardTarget * runMetersForToday) /
              dailyRunTargetMeters
              <br />
              {`${Number(accumulatedRewardBefore)} + (${Number(
                data.dailyRewardTarget
              )} * ${Number(secondRandomness)} % 6) / 10 + (${Number(
                data.dailyRewardTarget
              )} * ${Number(data?.runMetersForToday)}) / ${Number(
                data?.dailyRunTargetMeters
              )} = `}
              {Math.round(
                Number(accumulatedRewardBefore) +
                  (Number(data.dailyRewardTarget) *
                    Math.floor(Number(secondRandomness) % 6)) /
                    10 +
                  (Number(data.dailyRewardTarget) *
                    Number(data?.runMetersForToday)) /
                    Number(data.dailyRunTargetMeters)
              )}
            </td>
          </tr>
          <tr
            style={{
              backgroundColor:
                rewardMode === "6" || rewardMode === "7"
                  ? "#00cc88"
                  : "transparent",
              color:
                rewardMode === "6" || rewardMode === "7"
                  ? "#ffffff"
                  : "inherit",
            }}
          >
            <td>
              6 or 7: Random Reward Bonus + Direct Reward Proportional to the
              Run Amount
            </td>
            <td>
              {" "}
              accumulatedReward + dailyRewardTarget * 3 * (((runMetersForToday -
              (dailyRunTargetMeters * 2) / 3) / dailyRunTargetMeters)
              <br />
              {`(${Number(accumulatedRewardBefore)} + ${Number(
                data.dailyRewardTarget
              )} * 3 * ((${Number(data?.runMetersForToday)} - (${Number(
                data?.dailyRunTargetMeters
              )} * 2 )/ 3) / ${Number(data.dailyRunTargetMeters)}) = `}
              {Math.round(
                (Number(accumulatedRewardBefore) * 1000 +
                  Number(data.dailyRewardTarget) *
                    3 *
                    (((Number(data?.runMetersForToday) -
                      (Number(data?.dailyRunTargetMeters) * 2) / 3) *
                      1000) /
                      Number(data.dailyRunTargetMeters))) /
                  1000
              )}
            </td>
          </tr>

          <tr
            style={{
              backgroundColor: rewardMode === "8" ? "#00cc88" : "transparent",
              color: rewardMode === "8" ? "#ffffff" : "inherit",
            }}
          >
            <td>
              8: Random Reward Bonus + Direct Reward Proportional to the Run
              Amount
            </td>
            <td>
              multiplier = 1 + runMetersForToday / dailyRunTargetMeters / 3
              <br />
              accumulatedReward = accumulatedReward * multiplier
              <br />{" "}
              {`${Number(accumulatedRewardBefore)} * (1 + (${Number(
                data.runMetersForToday
              )} / ${Number(data.dailyRunTargetMeters)} / 3) = `}
              {Math.round(
                (Number(accumulatedRewardBefore) *
                  (1000 +
                    (Number(data?.runMetersForToday) * 1000) /
                      Number(data.dailyRunTargetMeters) /
                      3)) /
                  1000
              )}
            </td>
          </tr>
          <tr
            style={{
              backgroundColor: rewardMode === "9" ? "#00cc88" : "transparent",
              color: rewardMode === "9" ? "#ffffff" : "inherit",
            }}
          >
            <td>
              9: Random Reward Bonus + Direct Reward Proportional to the Run
              Amount
            </td>
            <td>
              multiplier = 1 + (2 * (runMetersForToday - dailyRunTargetMeters))
              / dailyRunTargetMeters
              <br />
              accumulatedReward = accumulatedReward * multiplier
              <br />{" "}
              {`${Number(accumulatedRewardBefore)} * (1 + (2 * (${Number(
                data?.runMetersForToday
              )} - ${Number(data.dailyRunTargetMeters)}) / ${Number(
                data.dailyRunTargetMeters
              )})) = `}
              {Math.round(
                Number(accumulatedRewardBefore) *
                  (1 +
                    (2 *
                      (Number(data?.runMetersForToday) -
                        Number(data.dailyRunTargetMeters))) /
                      Number(data.dailyRunTargetMeters))
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default RewardsComputation;
