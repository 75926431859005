import React, { useEffect, useState, useContext } from "react";
import DataContext from "../Context/DataContext";
import { ethers } from "ethers";
import backgroundImage from "../assets/historyrun.png";
import Background from "./Background";
import Spinner from "./Spinner";

const History = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [contractJustDeployed, setContractJustDeployed] = useState(false);
  const [events, setEvents] = useState([]);
  const data = useContext(DataContext);

  const getBlockTimeStamp = async (provider, blockNumber) => {
    const block = await data?.provider?.getBlock(blockNumber);
    const unixTimestamp = block.timestamp;
    const dateTime = new Date(block.timestamp * 1000);
    const formattedDateTime = dateTime.toLocaleString("en-US", {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
      // hour: "2-digit",
      // minute: "2-digit",
      // hour12: true,
    });
    return { formatted: formattedDateTime, original: unixTimestamp };
  };

  // Fetch data on mount and when accumulatedReward change
  useEffect(() => {
    setIsLoading(true);
    const getPastEvents = async (
      carrotStickExerciser,
      rewardsGenerated,
      fromBlock = 5349124,
      toBlock = "latest"
    ) => {
      const events = await (data?.carrotStickExerciser?.queryFilter(
        data?.carrotStickExerciser?.filters.rewardsGenerated(),
        fromBlock,
        toBlock
      ) || []);
      if (events?.length === 0) {
        console.log("no events found");
        setIsLoading(false);
        setContractJustDeployed(true);
        return;
      } else {
        const eventsWithTimestamps = await Promise.all(
          events.map(async (event) => {
            const { formatted, original } = await getBlockTimeStamp(
              data.provider,
              event.blockNumber
            );
            return {
              ...event,
              timestamp: formatted,
              originalTimestamp: original,
            };
          })
        );
        console.log(eventsWithTimestamps);
        const sortedEventsWithTimestamps = eventsWithTimestamps.sort((a, b) => {
          return b.originalTimestamp - a.originalTimestamp;
        });
        console.log(sortedEventsWithTimestamps);
        setEvents(sortedEventsWithTimestamps);
        setIsLoading(false);
      }
    };
    if (data?.carrotStickExerciser) {
      getPastEvents();
    }
  }, [data?.accumulatedReward]);

  return (
    <div className="overflow-x-auto m-3">
      {!contractJustDeployed && !isLoading && (
        <div className="mt-4 md:text-base ml-2 mr-2 text-xs max-h-[40rem] overflow-y-auto">
          <table className="table-fixed w-full text-center  ">
            <thead className="sticky top-0 bg-white mb-[rem]">
              <th className="w-1/5 px-6 py-3 text-center align-middle text-xs md:text-base font-medium text-gray-700 uppercase tracking-wider">
                Claim Date
              </th>
              <th className="w-1/5 px-6 py-3 text-center align-middle text-xs md:text-base font-medium text-gray-700 uppercase tracking-wider">
                Type
              </th>
              <th className="w-1/5 px-6 py-3 text-center align-middle text-xs md:text-base font-medium text-gray-700 uppercase tracking-wider">
                Reward Mode
              </th>
              <th className="w-1/5 px-6 py-3 text-center align-middle text-xs md:text-base font-medium text-gray-700 uppercase tracking-wider">
                Rewards Earned
              </th>
              <th className="w-1/5 px-6 py-3 text-center align-middle text-xs md:text-base font-medium text-gray-700 uppercase tracking-wider">
                Total
              </th>
            </thead>
            <tbody className="text-gray-500">
              {events?.map((event, index) => {
                const rewardsEarned = parseInt(
                  ethers.formatUnits(event.args[4], 6)
                );
                const rewardsEarnedDisplay =
                  rewardsEarned === 0 ? "USD 1000" : `USD ${rewardsEarned}`;
                const rewardModeDisplay =
                  event.args[5] === "gym / pilates"
                    ? "NA"
                    : event.args[0].toString();
                return (
                  <tr key={index}>
                    <td>{event.timestamp}</td>
                    <td>{event.args[5]}</td>
                    <td>{rewardModeDisplay}</td>
                    <td>{rewardsEarnedDisplay}</td>
                    <td>
                      USD {parseInt(ethers.formatUnits(event.args[3], 6))}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
      {isLoading && (
        <Spinner>
          <p className="mt-[-7rem] ">Loading...</p>
        </Spinner>
      )}

      {contractJustDeployed && (
        <div className="mt-[-8rem] text-center flex flex-col justify-center items-center h-screen">
          <p className="mt-[-10rem]">
            Contact has just been deployed with a USD {data.dailyRewardTarget}{" "}
            bonus locked in.
            <br />
            Unlock this bonus now on top of the daily reward with your first run
            / gym / pilates session.
          </p>
        </div>
      )}
    </div>
  );
};

export default History;
